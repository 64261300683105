$(() => {
  $('body').on('click', '.expected-planned-amount', function () {
    const button = $(this)
    const row = button.parents('tr')
    const input = row.find('.form-planned-amount')
    input.val(button.text())
    input.trigger('change')
    return false
  })
})
