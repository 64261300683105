import flatpickr from 'flatpickr'
import 'flatpickr/dist/l10n/nl'

$(() => {
  $('.datepicker').flatpickr({
    locale: 'nl',
    dateFormat: 'd-m-Y'
  })

  // https://github.com/flatpickr/flatpickr/pull/2112
  $('body').on('focus', '.datetimepicker:not(.active)', function () {
    flatpickr(this, {
      enableTime: true,
      time_24hr: true,
      locale: 'nl',
      dateFormat: 'd-m-Y H:i',
      onClose: function (selectedDates, dateString, instance) {
        if (!instance.element.classList.contains('autosubmit')) {
          return
        }
        const form = instance.element.form
        form.getAttribute('data-remote') ? Rails.fire(form, 'submit') : form.submit()
      }
    })
  })

  $('.datepicker-clear').click(function (event) {
    const datepicker = $(event.target).closest(':has(.datepicker)').find('.datepicker').flatpickr()
    datepicker.clear()
    return false
  })
})
