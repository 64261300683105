$(() => {
  const modal = $('#remote-modal')

  const show = event => {
    $.get($(event.target).attr('href'), data => {
      modal.html(data)
      modal.modal('show')
    })
    return false
  }

  $(document).on('click', '[data-toggle=remote-modal]', show)
})
